.refer-a-friend-page {
  .refer-main-container-from-page {
    @media (max-width: 750px) {
      width: 100%;
      padding-top: 33px;
    }
    @media (max-width: 600px) {
      padding-top: 0px;
    }

    .contact-item {
      @media (max-width: 750px) {
        width: auto;
        min-width: 88px;
      }
      @media (max-width: 485px) {
        font-size: 13px;
        min-width: auto;
      }
    }

    .margin-container {
      @media (max-width: 600px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .refer-email-success-button {
    width: 100%;
    height: 72px;
  }
}
